import { Memberships } from '@app/api/resources/memberships'
import { Membership, UserTenant } from '@ui/types'
import { createContext, useContext, useEffect, useState } from 'react'

import { default as api } from '../../api'
import { UpdatableTenantRequest } from '../../api/resources/tenancies'
import { Whoami } from '../../state/initialState'
import { TenanciesDataContextType } from './TenanciesDataContext.types'

// TODO: Use react-query to handle API calls and loading states
// TODO: Replace references to UserTenant with more suitable types from the member/ships APIs

const TenanciesDataContext = createContext<TenanciesDataContextType>({
  whoAmI: undefined,
  tenanciesData: [],
  currentTenantDetail: {
    display_name: '',
    identity: '',
  },
  isLoadingTenancyData: false,
  isErrorTenancyData: false,
  updateTenantName: async () => {
    return {}
  },
  resolved: false,
})

const TenanciesDataContextProvider = ({ children }: any) => {
  const [whoAmI, setWhoAmI] = useState<Whoami>()
  const [currentTenantDetail, setCurrentTenantDetail] = useState<UserTenant>()
  const [tenanciesData, setTenanciesData] = useState<UserTenant[]>()
  const [isLoadingTenancyData, setIsLoadingTenancyData] = useState<boolean>(true)
  const [isErrorTenancyData, setIsErrorTenancyData] = useState<boolean>(true)
  const [resolved, setResolved] = useState<boolean>(false)

  const updateTenantName = async (data: UpdatableTenantRequest) => {
    setIsLoadingTenancyData(true)
    try {
      const resp = await api.tenancies.updateTenant(data)
      setCurrentTenantDetail(resp)
      setIsErrorTenancyData(false)
    } catch (err) {
      setIsErrorTenancyData(true)
      throw err
    } finally {
      setIsLoadingTenancyData(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    let whoami: Whoami
    try {
      whoami = await api.whoami.get()
      setWhoAmI(whoami)
    } catch (err) {
      setIsLoadingTenancyData(false)
      setIsErrorTenancyData(true)
      setWhoAmI({
        principal: {
          email: '',
          issuer: '',
          subject: '',
          display_name: '',
        },
        roles: [],
        avatarUrl: '',
        tenantid: '',
        is_social: false,
        err: err.response?.status,
        tier: '',
        expired: false,
        userid: '',
      })
      return
    } finally {
      setResolved(true)
    }

    try {
      const response: Memberships = await api.memberships.getMemberships()

      if (response?.memberships && response.memberships?.length) {
        setTenanciesData(
          response.memberships.map((membership: Membership) => ({
            identity: membership.tenant_id,
            display_name: membership.display_name,
          }))
        )

        const currentTenantDetail: UserTenant = response.memberships
          .filter((membership: Membership) => {
            return membership.tenant_id === whoami.tenantid
          })
          .map((membership: Membership) => ({
            identity: membership.tenant_id,
            display_name: membership.display_name,
          }))[0]

        if (currentTenantDetail) {
          setCurrentTenantDetail({
            display_name: currentTenantDetail?.display_name,
            identity: currentTenantDetail?.identity,
          })
        } else {
          setCurrentTenantDetail({
            display_name: '',
            identity: whoAmI?.tenantid || '',
          })
        }
      }
      setIsLoadingTenancyData(false)
      setIsErrorTenancyData(false)
    } catch (err) {
      setIsLoadingTenancyData(false)
      setIsErrorTenancyData(true)
    }
  }

  return (
    <TenanciesDataContext.Provider
      value={{
        whoAmI,
        tenanciesData,
        currentTenantDetail,
        isLoadingTenancyData,
        isErrorTenancyData,
        updateTenantName,
        resolved,
      }}
    >
      {children?.length ? { ...children } : children}
    </TenanciesDataContext.Provider>
  )
}

export const useTenanciesDataContext = () => useContext(TenanciesDataContext)

export { TenanciesDataContext, TenanciesDataContextProvider }
