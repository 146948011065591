import { Member, MembersResponse, RkvstResourceCaps, UpdateMemberRequest } from '@ui/types'

import api from '../core'

const group = 'iam'
const resource = 'members'
const version = '1' // note missing V as parseResourceUrl appends the v...
const resourceUrl = api.parseResourceUrl(group, resource, version)

export default {
  /**
   * get list of tenant memberships and their respective display_name
   */
  getMembers(includeInactive: boolean, nextPageToken?: string): Promise<MembersResponse> {
    const filters: string[] = []
    if (nextPageToken !== undefined) {
      filters.push(`page_token=${nextPageToken}`)
    }

    if (includeInactive) {
      filters.push(`member_state=MEMBER_STATE_BOTH`)
    }

    let queryUrl = resourceUrl
    for (let i = 0; i < filters.length; i++) {
      if (i == 0) {
        queryUrl += `?${filters[i]}`
        continue
      }

      queryUrl += `&${filters[i]}`
    }

    const result = api.get<MembersResponse>(queryUrl)

    // XXX: This typing assertion only works incidentally because it handles strings.
    return result as Promise<MembersResponse>
  },

  updateMember(update: UpdateMemberRequest): Promise<Member> {
    const memberUUID = update.identity.split('/')[1]
    const result = api.patch(`${resourceUrl}/${memberUUID}`, JSON.stringify(update.update), {
      headers: { 'content-type': 'application/json' },
    })

    return result
  },

  activateMember(identity: string): Promise<Member> {
    const memberUUID = identity.split('/')[1]
    const result = api.post(`${resourceUrl}/${memberUUID}:activate`, {})

    return result
  },

  deactivateMember(identity: string): Promise<Member> {
    const memberUUID = identity.split('/')[1]
    const result = api.post(`${resourceUrl}/${memberUUID}:deactivate`, {})

    return result
  },

  getCaps(): Promise<RkvstResourceCaps> {
    const result = api.get<RkvstResourceCaps>(`${api.parseCapsUrl('members')}`)
    return result as Promise<RkvstResourceCaps>
  },
}
