import { queryClient } from '@app/state/queryClient'
import { initialise as initialiseUserStats } from '@app/utils/userStats'
import { QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Axios from 'axios'
import React, { Suspense, useEffect, useState } from 'react'
import { lazily } from 'react-lazily'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Store } from 'redux'
import styled, { ThemeProvider } from 'styled-components'

import { MenuPlaceholder } from './suspense'
const { Alerts } = lazily(() => import('./common/Alerts'))
const AppHeader = React.lazy(() => import('./common/AppHeader'))
const Navigation = React.lazy(() => import('./common/Navigation'))
const Capped = React.lazy(() => import('./components/Capped'))
import { LoadingProvider } from './components/Loading'
const RateLimited = React.lazy(() => import('./components/RateLimited'))
import { TenanciesDataContextProvider, useTenanciesDataContext } from './context/TenanciesDataContext'
import Css, { theme } from './Css'
import AllRoutes from './Routes'
import { ReduxState } from './state/initialState'
import { initGlobalFlags } from './utils/getFeatureFlag'
// FIXME: currently both themes are not prepared to work
// together shamesly as both, the ui app and the components storybook
// have been grown indendenly of each other. We need to come with a solution
// to integrate all the themes accorss the ui in a shamesly mater.
// Please note that this is anything related with the monorepo. We woul have
// the exact same problem if we didn't use it. Is the problem of importing the
// inividual compoennt which does not have it's own them provider baked and therfore
// it get it from the ui app instead. If we import from the library a component
// wrapped in a theme provider from it's own repo instead of the reaw component that
// only uses theme context wouldn't be a porblem. However this needs to be though thorughfully
// and agree an standard to do this. By now we work aroung it by importing the components
// theme here but this should be propperly addressed soon
// import { theme as componentsTheme }  from '@ui/components'

type Props = {
  store: Store<ReduxState>
}

const Main = styled.main<{
  navVisible: boolean
  publicRoute: boolean
}>`
  height: ${(p) => (p.navVisible ? `calc(100vh - ${theme.layout.headerHeight}px)` : '100vh')};
  padding-top: ${(p) => (p.publicRoute || p.navVisible ? '64px' : '0')};

  @media (min-width: ${theme.layout.fixedNavHeaderBreakpoint}px) {
    margin-left: ${(p) => (p.navVisible ? '260px' : '0')};
    padding-top: ${(p) => (p.navVisible || p.publicRoute ? `${theme.layout.headerHeight}px` : '0')};
    margin-top: 0;
  }
`

const shouldShowLoggedInChrome = (isLoggedOutRoute: boolean, props: any) => {
  return !isLoggedOutRoute && !!props?.tenantid
}

const DATATRAILS: React.FC<any> = (props) => {
  const [navVisibleMobile, setNavVisibleMobile] = useState(false)
  const { whoAmI } = useTenanciesDataContext()

  const publicRoute = location.pathname.includes('/public') || location.pathname.includes('/instaproof')

  // TODO: Check that redirects to logged-out handle this okay.
  const isLoggedOutRoute = location.pathname.includes('/logged-out')
  //const loggedIn = props.store.getState().whoami.err == null

  const toggleNavVisibleMobile = () => {
    setNavVisibleMobile(!navVisibleMobile)
  }
  const showLoggedInChrome = shouldShowLoggedInChrome(isLoggedOutRoute, whoAmI)

  return (
    <Alerts show={true} navVisible={showLoggedInChrome && window.innerWidth > theme.layout.fixedNavHeaderBreakpoint}>
      {(publicRoute || showLoggedInChrome) && (
        <AppHeader
          toggleNavVisibleMobile={toggleNavVisibleMobile}
          navVisibleMobile={navVisibleMobile}
          loggedIn={!!whoAmI?.tenantid}
          navVisible={!publicRoute}
        />
      )}
      {showLoggedInChrome && (
        <Navigation
          text=""
          copyText=""
          visibleMobile={navVisibleMobile}
          toggleVisibleMobile={toggleNavVisibleMobile}
          setNavVisibleMobile={setNavVisibleMobile}
        />
      )}

      <Main navVisible={showLoggedInChrome} publicRoute={publicRoute}>
        <AllRoutes />
      </Main>
    </Alerts>
  )
}

const Presentation: React.FC<Props> = (props) => {
  const [capresource, setCapresource] = useState('')
  const [capped, setCapped] = useState(false)

  Axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 402) {
        setCapresource(error.config.url)
        setCapped(true)
      }
      throw error
    }
  )

  useEffect(() => {
    initGlobalFlags()
  }, [])

  // Initialise user stats gathering
  initialiseUserStats()

  return (
    // @ts-ignore
    <ThemeProvider theme={theme}>
      <ReduxProvider store={props.store}>
        <QueryClientProvider client={queryClient}>
          <Css />
          <RateLimited>
            <TenanciesDataContextProvider>
              <Suspense fallback={<MenuPlaceholder />}>
                {capped && <Capped object={capresource}></Capped>}
                <LoadingProvider>
                  <BrowserRouter>
                    <DATATRAILS></DATATRAILS>
                  </BrowserRouter>
                </LoadingProvider>
              </Suspense>
            </TenanciesDataContextProvider>
          </RateLimited>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ReduxProvider>
    </ThemeProvider>
  )
}

const App = Presentation

export default App
