import { pickGap } from '@ui/themes'
import styled from 'styled-components'

import { SectionCard } from '../../molecules'
import { ModalProps } from './Modal.types'

export const BackDrop = styled.div<{ withoutBackDrop?: boolean }>`
  ${({ theme, withoutBackDrop }) => `
    backdrop-filter: ${!withoutBackDrop ? theme?._v1?.blurs.lg : 'normal'};
  `}
  position: fixed;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalWrapper = styled(SectionCard)<{ variant: ModalProps['variant']; hasIcon: boolean }>`
  // Workaround because the divs break on iOS.
  -webkit-overflow-scrolling: touch;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: 100vw;
  overflow: auto;
  container-type: inline-size;
  container-name: modal-wrapper;

  ${({ theme, variant, hasIcon }) => `
    border-radius: ${pickGap(theme?._v1, 'size3').px};
    padding: ${pickGap(theme?._v1, 'size6').px};
    .modal-title {
      display: grid;
      gap: 16px;
      text-align: ${variant === 'center' ? 'center' : 'left'};
    }
    .modal-header {
      justify-content: ${hasIcon && variant === 'left' ? 'space-between' : 'flex-end'};
    }
    .header-icon {
      justify-content: ${variant === 'left' ? 'flex-start' : 'center'};
    }
  `}

  .modal-header, .header-icon {
    width: 100%;
    display: flex;

    svg {
      cursor: pointer;
    }
  }
`

export const ModalContentWrapper = styled.section`
  display: grid;
  gap: 32px;
  max-width: 100vw;
  max-height: 90vh;
  overflow: auto;
`

export const HeaderWrapper = styled.header`
  ${({ theme }) => `
    margin-top: ${pickGap(theme?._v1, 'size5').px};
  `}
  text-align: center;
`

export const ButtonsWrapper = styled.footer`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  flex-wrap: nowrap;

  button {
    width: 100%;
    flex-grow: 1;
  }

  ${({ theme }) => `
    gap: ${pickGap(theme?._v1, 'size2').px};
  `}
`

/**
 * For when we want the body of the modal to be alined at the left side
 * and if we don't want to write a new styled component for this
 * as i've found is a recurrent pattern
 */
export const TextWrapper = styled.p`
  text-align: left;
  margin-top: 0px;

  ${({ theme }) => `
    li {
      margin-top: ${pickGap(theme?._v1, 'size4').px};
    }
    ul {
      padding-left: ${pickGap(theme?._v1, 'size4').px};
    }
  `}
`
