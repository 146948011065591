import { Membership } from '@ui/types'

import api from '../core'

const group = 'iam'
const resource = 'memberships'
const version = '1' // note missing V as parseResourceUrl appends the v...
const resourceUrl = api.parseResourceUrl(group, resource, version)

export type Memberships = { memberships: Membership[] }

export default {
  /**
   * get list of tenant memberships and their respective display_name
   */
  getMemberships(): Promise<Memberships> {
    const result = api.get<Memberships>(resourceUrl)

    // XXX: This typing assertion only works incidentally because it handles strings.
    return result as Promise<Memberships>
  },
}
